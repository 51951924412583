/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { formatRelativeTime, formatToLongDate, isPresent, transEnum } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    FieldCheckbox,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldNumeric,
    FieldTextControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import {
    AwardScenarioDTO,
    QuoteRequestDTO,
    QuoteRequestLineItemDTO,
    QuoteRequestLineItemStatus,
    QuoteRequestStatus,
} from '@luminovo/http-client';
import { Refresh } from '@mui/icons-material';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { LayoutCard } from '../../../components/LayoutCard';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { getRandomFloat } from '../../../utils/randomizerFunctions';
import { UrlParams } from '../../../utils/routes/routes';
import { ButtonDiscardQuoteRequest } from '../components/ButtonDiscardQuoteRequest';
import { ButtonDownloadQuoteRequestExcel } from '../components/ButtonDownloadQuoteRequestExcel';
import { ButtonNegotiationQuoteImporter } from '../components/ButtonNegotiationQuoteImporter';
import { ButtonSendQuoteRequests } from '../components/ButtonSendQuoteRequests';
import { FieldGlobalAwardScenario } from '../components/FieldSelectAwardScenario';
import { MultiProgressPieChart } from '../components/MultiProgressPieChart';
import { NegotiationsLayout } from '../components/NegotiationsLayout';
import { QuoteRequestStatusChip } from '../components/QuoteRequestStatusChip';
import { TableQuoteRequestLineItems } from '../components/TableQuoteRequestLineItems';
import { TableQuoteRequestLineItemsForm } from '../components/TableQuoteRequestLineItemsForm';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { useQuoteRequest, useQuoteRequestLineItemsByQuoteRequest } from '../hooks/negotiationHandlers';
import { useSubmitLineItemPrices } from '../hooks/useSubmitLineItemTargetPrice';
import {
    extractAwaredOfferFromScenario,
    extractTargetPrice,
    extractTargetPriceSavings,
} from '../model/extractors/extractorNegotionsLineItem';
import { formatQuoteRequest } from '../model/formatQuoteRequest';
import { quoteRequestLineItemStatusTranslations } from '../model/getQuoteRequestLineItemStatus';
import { getUnitPriceDiscount } from '../model/getUnitPriceDiscount';
import { monetaryValue } from '../model/monetaryValueMath';
import { QuoteRequestForm } from '../model/quoteRequestForm';

export function QuoteRequestDetailsPage({
    pathParams,
}: UrlParams<'/negotiations/:negotiationId/quote-requests/:quoteRequestId'>) {
    const negotiationId = Number(pathParams.negotiationId);
    const quoteRequestId = Number(pathParams.quoteRequestId);
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (!isPresent(quoteRequest)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <NegotiationsLayout
            negotiationId={negotiationId}
            quoteRequestId={quoteRequestId}
            actions={
                <>
                    <ButtonDiscardQuoteRequest quoteRequestId={quoteRequestId} />
                    <ButtonDownloadQuoteRequestExcel quoteRequestId={quoteRequestId} />
                    <ButtonNegotiationQuoteImporter />
                    <ButtonSendQuoteRequests quoteRequests={[quoteRequest]} />
                </>
            }
            style={{ backgroundColor: colorSystem.neutral[1] }}
        >
            <Flexbox flexDirection="column" gap={12} padding={'12px'}>
                <Flexbox gap={4}>
                    <Text variant="h1">{formatQuoteRequest(quoteRequest)}</Text>
                    <QuoteRequestStatusChip status={quoteRequest.status} />
                </Flexbox>
                <QuoteRequestFormContainer negotiationId={negotiationId} quoteRequest={quoteRequest} />
            </Flexbox>
        </NegotiationsLayout>
    );
}

function QuoteRequestFormContainer({
    quoteRequest,
    negotiationId,
}: {
    negotiationId: number;
    quoteRequest: QuoteRequestDTO;
}) {
    const { referenceScenario } = useNegotiationsContext();
    const { data: quoteRequestLineItems } = useQuoteRequestLineItemsByQuoteRequest(quoteRequest.id);

    const onSubmit = useSaveQuoteRequest();

    if (!isPresent(quoteRequestLineItems) || !isPresent(referenceScenario)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const defaultValues: QuoteRequestForm = {
        quoteRequest,
        globalTargetSavings: { type: 'fixed', value: 5 },
        showCustomerName: !(quoteRequest.configuration?.hidden_fields.includes('Customer') ?? true),
        showTargetPrice: !(quoteRequest.configuration?.hidden_fields?.includes('TargetPrice') ?? true),
        lineItemTargetPrices: quoteRequestLineItems.map((lineItem) => ({
            quoteRequestLineItemId: lineItem.id,
            targetPrice: extractTargetPrice(lineItem, referenceScenario),
        })),
        lineItemTargetSavings: quoteRequestLineItems.map((lineItem) => ({
            quoteRequestLineItemId: lineItem.id,
            targetSavings: (extractTargetPriceSavings(lineItem, referenceScenario) ?? 0) * 100,
        })),
    };

    return (
        <FormContainer
            // Invalidate the form when we change the status
            key={quoteRequest.status}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            UNSAFE_disableStablePropCheck
        >
            <Box sx={{ display: 'grid', gridTemplateColumns: 'minmax(15vw, auto) 1fr', gap: 2, height: '100%' }}>
                <Flexbox flexDirection="column" gap="16px">
                    <LayoutCard>
                        {quoteRequest.status !== QuoteRequestStatus.NotSent &&
                        quoteRequest.status !== QuoteRequestStatus.Discarded ? (
                            <QuoteRequestLineItemsStatusPieChart quoteRequestLineItems={quoteRequestLineItems} />
                        ) : (
                            <QuoteRequestLineItemsNotSent quoteRequestLineItemsLength={quoteRequestLineItems.length} />
                        )}
                        {/* TODO(negotiations): Add reference volume, target volume, and quoted volume
                        <PropertiesTable
                        properties={
                            {
                                // [`Reference volume`]: '0',
                                // [`Target volume`]: '0',
                                // [`Quoted volume`]: '0',
                            }
                        }
                    /> */}
                    </LayoutCard>

                    <LayoutCard>
                        <FormItemSentOn />

                        <FormItemSentBy />

                        <FormItemDueDate />

                        <FormItemRecievedOn />

                        <FormItemNotes />

                        <FormItemOptionalColumns />

                        <FormItemReferenceScenario lineItems={quoteRequestLineItems} negotiationId={negotiationId} />

                        <FormItemExtraTargetSavings lineItems={quoteRequestLineItems} />
                    </LayoutCard>
                </Flexbox>

                <LayoutCard style={{ height: '80vh' }}>
                    {quoteRequest.status === QuoteRequestStatus.NotSent && (
                        <TableQuoteRequestLineItemsForm key={referenceScenario?.id} lineItems={quoteRequestLineItems} />
                    )}
                    {quoteRequest.status !== QuoteRequestStatus.NotSent && (
                        <TableQuoteRequestLineItems lineItems={quoteRequestLineItems} quoteRequest={quoteRequest} />
                    )}
                </LayoutCard>
            </Box>
        </FormContainer>
    );
}

function QuoteRequestLineItemsNotSent({ quoteRequestLineItemsLength }: { quoteRequestLineItemsLength: number }) {
    return (
        <Flexbox flexDirection="column" rowGap={1}>
            <Text variant="body-small" color={colorSystem.neutral[8]}>{t`Line items to be sent`}</Text>
            <Text variant="h4">{quoteRequestLineItemsLength}</Text>
        </Flexbox>
    );
}

function QuoteRequestLineItemsStatusPieChart({
    quoteRequestLineItems,
}: {
    quoteRequestLineItems: QuoteRequestLineItemDTO[];
}) {
    const quoteRequestLineItemStatuses = quoteRequestLineItems.map((li) => li.status);

    const lineItemData = [
        {
            label: transEnum(QuoteRequestLineItemStatus.Sent, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.Sent).length,
            color: colorSystem.blue[4],
        },
        {
            label: transEnum(QuoteRequestLineItemStatus.Received, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.Received).length,
            color: colorSystem.green[3],
        },
        {
            label: transEnum(QuoteRequestLineItemStatus.NoBid, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.NoBid).length,
            color: colorSystem.violet[3],
        },
    ];

    if (quoteRequestLineItems.length === 0) {
        return null;
    }
    return <MultiProgressPieChart data={lineItemData} margin="30px" width={120} height={120} />;
}

function useIsReadonly() {
    const { control } = useFormContext<QuoteRequestForm>();
    const quoteRequest = useWatch({ control, name: 'quoteRequest' });
    return quoteRequest.status !== QuoteRequestStatus.NotSent;
}

function FormItemContainer({ label, read, edit }: { label: string; read: React.ReactNode; edit: React.ReactNode }) {
    const content = useIsReadonly() ? read : edit;
    if (!content) {
        return null;
    }
    return <FormItem label={label}>{content}</FormItem>;
}

function FormItemNotes() {
    const { control } = useFormContext<QuoteRequestForm>();
    const notes = useWatch({ control, name: 'quoteRequest.notes' });
    const { isLoading, mutateAsync: submit } = useSubmitQuoteRequestForm();

    return (
        <FormItemContainer
            label="Notes"
            read={
                <Text
                    style={{
                        whiteSpace: 'pre-wrap',
                        display: 'inline-block',
                        overflow: 'hidden',
                        maxWidth: '200px',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {notes || `-`}
                </Text>
            }
            edit={
                <FieldTextControlled
                    control={control}
                    name="quoteRequest.notes"
                    FieldProps={{
                        disabled: isLoading,
                        placeholder: 'Start typing...',
                        multiline: true,
                        minRows: 2,
                        InputProps: {
                            onBlur: () => submit(),
                        },
                    }}
                />
            }
        />
    );
}

function FormItemRecievedOn() {
    const { control } = useFormContext<QuoteRequestForm>();
    const recived_date = useWatch({ control, name: 'quoteRequest.received_date' });
    const isReadOnly = useIsReadonly();

    if (!isReadOnly) {
        return null;
    }

    return (
        <FormItem label="Received on">
            <Text>{isPresent(recived_date) ? formatToLongDate(recived_date) : '-'}</Text>
        </FormItem>
    );
}

function FormItemSentOn() {
    const { control } = useFormContext<QuoteRequestForm>();
    const sent_date = useWatch({ control, name: 'quoteRequest.sent_date' });

    if (!isPresent(sent_date)) {
        return null;
    }

    return (
        <FormItem label="Sent on">
            <Text>{formatToLongDate(sent_date)}</Text>
        </FormItem>
    );
}

function FormItemSentBy() {
    const { control } = useFormContext<QuoteRequestForm>();
    const sender = useWatch({ control, name: 'quoteRequest.sent_by' });

    if (!isPresent(sender)) {
        return null;
    }

    return (
        <FormItem label="Sent by">
            <Text>
                {sender.first_name} {sender.last_name}
            </Text>
        </FormItem>
    );
}

function FormItemDueDate() {
    const { control, getFieldState } = useFormContext<QuoteRequestForm>();
    const dueDate = useWatch({ control, name: 'quoteRequest.due_date' });
    const relative = dueDate && formatRelativeTime(dueDate);
    const error = getFieldState('quoteRequest.due_date').error;

    const { isLoading, mutateAsync: submit } = useSubmitQuoteRequestForm();

    return (
        <FormItemContainer
            label="Due date"
            read={<Text>{dueDate ? formatToLongDate(dueDate) : '-'}</Text>}
            edit={
                <>
                    <FieldDateControlled
                        control={control}
                        name="quoteRequest.due_date"
                        inFuture
                        minDate={new Date().toISOString()}
                        FieldProps={{
                            disabled: isLoading,
                            placeholder: 'Select due date...',
                            InputProps: {
                                onBlur: () => {
                                    submit();
                                },
                            },
                        }}
                    />
                    <Text>{!isPresent(error) && relative}</Text>
                </>
            }
        />
    );
}

function FormItemOptionalColumns() {
    const { control } = useFormContext<QuoteRequestForm>();
    const { isLoading, mutateAsync: submit } = useSubmitQuoteRequestForm();
    const { isLoading: isLoadingLineItemPrices, mutateAsync: submitLineItemPrices } = useSubmitLineItemPrices();

    return (
        <FormItemContainer
            label="Optional columns"
            read={undefined}
            edit={
                <>
                    <Flexbox flexDirection="row" gap="8px" alignItems="center">
                        <FieldCheckboxControlled
                            control={control}
                            name="showCustomerName"
                            FieldProps={{
                                disabled: isLoading || isLoadingLineItemPrices,
                                onChangeCapture: async () => {
                                    await submit();
                                    await submitLineItemPrices();
                                },
                            }}
                        />
                        <Text>Customer name</Text>
                        {isLoading && <CircularProgress size={12} />}
                    </Flexbox>
                    {/* TODO fix later 
                    <Flexbox flexDirection="row" gap="8px" alignItems="center">
                        <FieldCheckboxControlled
                            control={control}
                            name="showTargetPrice"
                            FieldProps={{
                                disabled: isLoading || isLoadingLineItemPrices,
                                onChangeCapture: async () => {
                                    await submit();
                                    await submitLineItemPrices();
                                },
                            }}
                        />
                        <Text>Target price</Text>
                        {isLoading && <CircularProgress size={12} />}
                    </Flexbox>
                    */}
                </>
            }
        />
    );
}

function FormItemReferenceScenario({
    negotiationId,
    lineItems,
}: {
    negotiationId: number;
    lineItems: QuoteRequestLineItemDTO[];
}) {
    const { referenceScenario } = useNegotiationsContext();
    const { control, setValue, getValues } = useFormContext<QuoteRequestForm>();
    const showTargetPrice = useWatch({ control, name: 'showTargetPrice' });
    const { isLoading, mutateAsync: submit } = useSubmitLineItemPrices();

    if (!showTargetPrice || !isPresent(referenceScenario)) {
        return null;
    }

    // We recaclucate the discountUnitPrice when we change award scenarios
    const handleScenarioChange = async (referenceScenario: AwardScenarioDTO) => {
        // Update the values
        setValue(
            'lineItemTargetPrices',
            lineItems.map((lineItem, index) => {
                const targetSaving = getValues(`lineItemTargetSavings.${index}.targetSavings`);

                const referenceUnitPrice = extractAwaredOfferFromScenario(
                    lineItem.negotiation_line_item_id,
                    referenceScenario,
                )?.awarded_solution.unit_price;

                const discountedUnitPrice = getUnitPriceDiscount(referenceUnitPrice ?? null, targetSaving);

                return {
                    quoteRequestLineItemId: lineItem.id,
                    targetPrice: discountedUnitPrice ?? monetaryValue.zero,
                };
            }),
        );

        // Then submit the form
        await submit();
    };

    return (
        <FormItemContainer
            label="What should the target price refer to?"
            read={undefined}
            edit={
                <FieldGlobalAwardScenario
                    onChange={handleScenarioChange}
                    type="referenceScenario"
                    negotiationId={negotiationId}
                    disabled={isLoading}
                />
            }
        />
    );
}

function FormItemExtraTargetSavings({ lineItems }: { lineItems: QuoteRequestLineItemDTO[] }) {
    const { control, setValue, getValues } = useFormContext<QuoteRequestForm>();
    const { referenceScenario } = useNegotiationsContext();
    const { isLoading, mutateAsync: submitLineItemPrices } = useSubmitLineItemPrices();

    function isPercentageInRange(value: number) {
        return value >= 0 && value <= 100;
    }
    const showTargetPrice = useWatch({ control, name: 'showTargetPrice' });

    if (!showTargetPrice || !isPresent(referenceScenario)) {
        return null;
    }

    const updateTargetSavings = (globalTargetSavings: QuoteRequestForm['globalTargetSavings']) => {
        const updatedLineItemSavings = getValues('lineItemTargetSavings').map((item) => ({
            quoteRequestLineItemId: item.quoteRequestLineItemId,
            targetSavings: getGlobalTargetSavingsValue(globalTargetSavings),
        }));

        // Set all the target savings to the global target savings
        setValue('lineItemTargetSavings', updatedLineItemSavings);

        // Recalcuate all the discounts
        setValue(
            'lineItemTargetPrices',
            lineItems.map((lineItem) => {
                // Find the line item target savings with the same id
                const lineItemSaving = updatedLineItemSavings.find(
                    (item) => item.quoteRequestLineItemId === lineItem.id,
                );

                const referenceUnitPrice = extractAwaredOfferFromScenario(
                    lineItem.negotiation_line_item_id,
                    referenceScenario,
                )?.awarded_solution.unit_price;

                // Get the discounted unit price
                const discountedUnitPrice = getUnitPriceDiscount(
                    referenceUnitPrice ?? null,
                    lineItemSaving?.targetSavings ?? 0,
                );

                return {
                    quoteRequestLineItemId: lineItem.id,
                    targetPrice: discountedUnitPrice ?? monetaryValue.zero,
                };
            }),
        );
    };

    const edit = (
        <Controller
            control={control}
            name="globalTargetSavings"
            rules={{
                validate: (value) => {
                    const values = value.type === 'fixed' ? [value.value] : [value.from, value.to];
                    if (values.some((value) => !isPercentageInRange(value))) {
                        return 'Invalid range';
                    }
                    if (value.type === 'random' && value.to <= value.from) {
                        return 'Invalid range';
                    }
                },
            }}
            render={({ field, formState }) => {
                const error = formState.errors.globalTargetSavings?.message;
                const type = field.value.type;
                return (
                    <>
                        <FieldNumeric
                            error={type === 'fixed' && Boolean(error)}
                            InputProps={{
                                disabled: type !== 'fixed' || isLoading,
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            value={field.value.type === 'fixed' ? field.value.value : null}
                            onChange={(value) => {
                                field.onChange({ type: 'fixed', value });
                            }}
                            onBlur={async () => {
                                // Change the prices on the table
                                // TODO(negotiations): Figure out how to validate the targetSavings before updating
                                updateTargetSavings(field.value);

                                // Submit the new prices
                                await submitLineItemPrices();
                            }}
                        />

                        <Flexbox gap="8px">
                            <FieldCheckbox
                                value={type === 'random'}
                                onChange={(value) => {
                                    field.onChange(
                                        value
                                            ? { type: 'random', from: 3, to: 7 }
                                            : {
                                                  type: 'fixed',
                                                  value: 5,
                                              },
                                    );
                                    field.onBlur();
                                }}
                                onBlur={field.onBlur}
                            />
                            <Text>Use random percentage</Text>
                        </Flexbox>

                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '90px 90px 1fr',
                                gap: 1,
                                height: '100%',
                            }}
                        >
                            <Text variant="h4">From</Text>
                            <Text variant="h4">To</Text>
                            <Text></Text>
                            <FieldNumeric
                                error={type === 'random' && Boolean(error)}
                                InputProps={{
                                    disabled: type !== 'random',
                                    placeholder: '',
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onBlur={field.onBlur}
                                value={field.value.type === 'random' ? field.value.from : null}
                                onChange={(value) => {
                                    if (type === 'random') {
                                        field.onChange({ type: 'random', from: value, to: field.value.to });
                                    }
                                }}
                            />

                            <FieldNumeric
                                error={type === 'random' && Boolean(error)}
                                sx={{ maxWidth: 90 }}
                                InputProps={{
                                    disabled: type !== 'random',
                                    placeholder: '',
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onBlur={field.onBlur}
                                value={field.value.type === 'random' ? field.value.to : null}
                                onChange={(value) => {
                                    if (type === 'random') {
                                        field.onChange({ type: 'random', from: field.value.from, to: value });
                                    }
                                }}
                            />

                            <SecondaryButton
                                onClick={async () => {
                                    updateTargetSavings(field.value);
                                    await submitLineItemPrices();
                                }}
                                isLoading={isLoading}
                                startIcon={<Refresh />}
                                disabled={type !== 'random'}
                            >
                                Randomize
                            </SecondaryButton>
                        </Box>
                    </>
                );
            }}
        />
    );
    return <FormItemContainer label="Extra target savings" read={undefined} edit={edit} />;
}

function useSaveQuoteRequest() {
    const { mutateAsync: saveQuoteRequest } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: null,
    });

    return async (form: QuoteRequestForm) => {
        await saveQuoteRequest({
            pathParams: { id: form.quoteRequest.id },
            requestBody: {
                due_date: form.quoteRequest.due_date,
                show_customer: form.showCustomerName,
                show_target_price: form.showTargetPrice,
                notes: form.quoteRequest.notes && form.quoteRequest.notes.length > 0 ? form.quoteRequest.notes : null,
            },
        });
    };
}

function useSubmitQuoteRequestForm() {
    const { handleSubmit } = useFormContext<QuoteRequestForm>();
    const onSubmit = useSaveQuoteRequest();
    const submit = handleSubmit(onSubmit);
    return useMutation({
        mutationFn: () => submit(),
    });
}

function getGlobalTargetSavingsValue(globalTargetSavings: QuoteRequestForm['globalTargetSavings']): number {
    switch (globalTargetSavings.type) {
        case 'fixed':
            return globalTargetSavings.value;
        case 'random':
            return getRandomFloat(globalTargetSavings.from, globalTargetSavings.to, 2);
    }
}
