import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { PartLiteRuntype } from '../part';
import { SupplierDTORuntype } from '../supplier';
import { SupplierAndStockLocationDTO } from '../supplierAndStockLocation';
import { UserDTORuntype } from '../user';

export enum QuoteRequestLineItemStatus {
    NotSent = 'NotSent', // not sent quote request
    Sent = 'Sent', // sent quote request
    Overdue = 'Overdue', // overdue quote request
    Received = 'Received', // received quote request with offer
    NoBid = 'NoBid', // received quote request, but no received offer
    Discarded = 'Discarded', // discarded quote request
}

export enum QuoteRequestStatus {
    NotSent = 'NotSent',
    Sent = 'Sent',
    Overdue = 'Overdue',
    Received = 'Received',
    Discarded = 'Discarded',
}

export type QuoteRequestDTO = r.Static<typeof QuoteRequestDTORuntype>;
export const QuoteRequestDTORuntype = r.Record({
    id: r.Number,
    supplier: SupplierDTORuntype,
    created_at: r.String,
    discarded: r.Boolean,
    due_date: r.String.nullable().optional(),
    received_date: r.String.nullable().optional(),
    sent_date: r.String.nullable().optional(),
    notes: r.String.nullable().optional(),
    line_item_count: r.Number,
    status: runtypeFromEnum(QuoteRequestStatus),
    sent_by: r
        .Record({
            id: r.String,
            first_name: r.String,
            last_name: r.String,
            email: r.String,
        })
        .nullable(),
    configuration: r
        .Record({
            hidden_fields: r.Array(
                r.Union(r.Literal('Customer'), r.Literal('TargetPrice'), r.Literal('NegotiatedByCustomer')),
            ),
        })
        .optional(),
});

export type QuoteRequestLineItemDTO = r.Static<typeof QuoteRequestLineItemDTORuntype>;
export const QuoteRequestLineItemDTORuntype = r.Record({
    id: r.Number,
    quote_request_id: r.Number,
    customers: r.String.optional().nullable(), // Refactor that in the future
    negotiation_line_item_id: r.Number,
    description: r.String.optional().nullable(),
    target_price: MonetaryValueBackendRuntype.optional().nullable(),
    required_quantity: QuantityUnitDTORuntype,
    potential_quantity: QuantityUnitDTORuntype,
    component_origin: r.String.optional().nullable(),
    requested_part: PartLiteRuntype,
    received_offer: r
        .Record({
            offer_id: r.Record({
                kind: r.Union(r.Literal('OffTheShelf'), r.Literal('Custom')),
                id: r.String,
            }),
            part: PartLiteRuntype,
            supplier_and_stock_location_id: r.String,
            unit_price: MonetaryValueBackendRuntype,
            moq: r.Number,
            mpq: r.Number,
        })
        .nullable(),
    status: runtypeFromEnum(QuoteRequestLineItemStatus),
});

export type AwardedOfferDTO = r.Static<typeof AwardedOfferDTORuntype>;
const AwardedOfferDTORuntype = r.Record({
    id: r.Number,
    award_scenario_id: r.Number,
    negotiation_line_item_id: r.Number,
    awarded_solution: r.Record({
        offer_id: r.Record({
            kind: r.Union(r.Literal('OffTheShelf'), r.Literal('Custom')),
            id: r.String,
        }),
        part: PartLiteRuntype,
        supplier_and_stock_location_id: r.String,
        unit_price: MonetaryValueBackendRuntype,
        moq: r.Number,
        mpq: r.Number,
    }),
});

export type AwardedOffer = Omit<AwardedOfferDTO, 'awarded_solution'> & {
    awarded_solution: Omit<AwardedOfferDTO['awarded_solution'], 'supplier_and_stock_location_id'> & {
        supplier_and_stock_location: SupplierAndStockLocationDTO | undefined | null;
    };
};

export type AwardScenarioDTO = r.Static<typeof AwardScenarioDTORuntype>;
export const AwardScenarioDTORuntype = r.Record({
    id: r.Number,
    name: r.String,
    negotiation_id: r.Number,
    created_at: r.String,
    awarded_offers: r.Array(AwardedOfferDTORuntype),
});
export type AwardScenario = Omit<AwardScenarioDTO, 'awarded_offers'> & {
    awarded_offers: AwardedOffer[];
};

export type NegotiationDTO = r.Static<typeof NegotiationDTORuntype>;
export const NegotiationDTORuntype = r.Record({
    id: r.Number,
    name: r.String,
    created_at: r.String,
    created_by: UserDTORuntype,
});

export type NegotiationLineItemDTO = r.Static<typeof NegotiationLineItemRuntype>;
export const NegotiationLineItemRuntype = r.Record({
    id: r.Number,
    negotiation_id: r.Number,
    quantity: QuantityUnitDTORuntype,
    parts: r.Array(
        r.Record({
            id: r.Number, // part option id
            part: PartLiteRuntype,
        }),
    ),
    awarded_offers: r.Array(AwardedOfferDTORuntype),
});

export type NegotiationLineItem = Omit<NegotiationLineItemDTO, 'awarded_offers'> & {
    awarded_offers: AwardedOffer[];
};

// Demand

export type DemandDTO = r.Static<typeof DemandDTORuntype>;
export const DemandDTORuntype = r.Record({
    id: r.String,
    context: r.String.nullable(),
    component_ipn_id: r.String.nullable(),
    created_at: r.String,
    quantity: QuantityUnitDTORuntype,
    delivery_start_date: r.String.nullable(),
    delivery_end_date: r.String.nullable(),
    customer: r
        .Record({
            id: r.String,
            name: r.String,
        })
        .nullable(),
    site: r
        .Record({
            id: r.String,
            name: r.String,
        })
        .nullable(),
    assembly: r
        .Record({
            id: r.String,
            designator: r.String,
        })
        .nullable(),
});

export const QuoteRequestLineItemAggregatedStatusesRuntype = r.Record({
    overview: r.Array(
        r.Record({
            status: runtypeFromEnum(QuoteRequestLineItemStatus),
            count: r.Number,
        }),
    ),
    statuses_by_negotiation_line_item: r.Array(
        r.Record({
            negotiation_line_item_id: r.Number,
            statuses: r.Array(
                r.Record({
                    status: runtypeFromEnum(QuoteRequestLineItemStatus),
                    count: r.Number,
                }),
            ),
        }),
    ),
});
